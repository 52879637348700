import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import { Seo } from 'components';
import PaymentDialog from './components/PaymentDialog';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { Analytics } from 'apis/Analytics';
import { HyrosApi } from 'apis/hyrosApi';
import { config as endpointConfig } from '../../../config';
import { Events } from 'utils/events';
import { useCheckoutData, usePageView, useQuizData } from 'utils/hooks';
import { getLocalisedProduct } from 'utils/localization';
import axios from 'axios';
import { handleError } from 'utils/error';
import {
  updateLead,
  updateQuizAnswers,
  updateShippingTestState,
} from 'state/user/effects';
import { klaviyoShipLink } from 'utils/klavyio';
import Tracking from 'utils/tracking';
import { store } from 'index';

const APP_SUB_KEYS = [
  'lasting-change-1-month',
  'lasting-change-3-month',
  'lasting-change-6-month',
  'lasting-change-1-month-downsell',
  'lasting-change-3-month-downsell',
  'lasting-change-6-month-downsell',
  'lasting-change-3-month-downsell-cm',
  'lasting-change-6-month-downsell-cm',
  'lasting-change-1-month-downsell-cm',
];

const Payments: FC<{ setModalOpen?: any; handleBack: () => void }> = ({
  setModalOpen,
  handleBack,
}) => {
  const {
    code,
    selected_plans,
    user,
    geolocation,
    selected_plans_options,
    upsell_products,
  } = useSelector((state: AppState) => state.user);
  const userEmail = useSelector((state: AppState) => state.user.user?.email);
  const userShippingAddress = useSelector(
    (state: AppState) => state.user.shipping_address,
  );

  const quiz_answers = useSelector(
    (state: AppState) => state.user.quiz_answers,
  );
  const config = useSelector((state: AppState) => state.config);
  const upgradeSequences = useQuizData('upgradeSequences');

  const {
    goToRegister,
    navigateBack,
    goToDiscount,
    goToShipping,
    goToSuccess,
    goToUpgrade,
  } = useRouter();

  const { data } = useCheckoutData();
  const dispatch = useDispatch();
  const pageOrder = data?.pageOrder || [];
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-lc';

  // usePageView({
  //   clientCode: code,
  //   country: geolocation?.iso_country,
  //   state: geolocation?.iso_state,
  //   gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  //   email: userEmail,
  // });
  const height = use100vh() ?? 0;

  useEffect(() => {
    if (!code) {
      return;
    }
  }, []);

  const localisedProducts = store
    .getState()
    .user.selected_plans?.map(product => getLocalisedProduct(product));

  const calculateFullPrice = products => {
    let fullPrice = 0;

    for (let i = 0; i < products.length; i++) {
      fullPrice = fullPrice + Number(products[i].finalPrice);
    }

    return fullPrice.toString();
  };

  const createProductNameString = products => {
    const prodNames = products.map(prod =>
      prod.name.replace('Lasting Change', '').trim(),
    );
    return prodNames.join(', ');
  };

  const createProductSkyString = products => {
    const prodNames = products.map(prod => prod.sku);
    return prodNames.join(', ');
  };

  const createHyrosOrder = async ({ orderId }: { orderId: number }) => {
    try {
      if (config?.ENV === 'development') {
        return;
      }
      await HyrosApi.createOrder({
        orderId: orderId ?? 0,
        email: user?.email ?? '',
        productName: createProductNameString(localisedProducts),
        productSky: createProductSkyString(localisedProducts),
        orderPrice: parseFloat(calculateFullPrice(localisedProducts)),
      });
    } catch (e) {
      handleError(e);
    }
  };

  const generateEbookLink = async () => {
    const bookString = quiz_answers.bookImageString;
    const index = bookString.indexOf('#');
    const finalString = bookString.substring(index);
    const result = finalString.replace(/\.png$/, '');
    try {
      const data = await axios.post(endpointConfig.GENERATE_EBOOK, {
        userName: quiz_answers.userName,
        bookColor: result,
        textColor: quiz_answers.textColor,
        email: userEmail,
        clientCode: code,
      });
      dispatch(updateQuizAnswers({ ebookUrl: data.data }));
      dispatch(
        updateLead(code, {
          quiz_answers: { ...quiz_answers, ebookUrl: data.data },
        }),
      );
    } catch (e) {
      handleError(e);
    }
  };

  const tryShipBook = async () => {
    dispatch(
      updateShippingTestState({
        isShippingTest: true,
        addressError: false,
        loading: true,
      }),
    );
    try {
      const bookString = quiz_answers.bookImageString;
      const index = bookString.indexOf('#');
      const finalString = bookString.substring(index);
      const result = finalString.replace(/\.png$/, '');
      await axios.post(endpointConfig.FIREBASE_SHIP_BOOK, {
        hardCover: quiz_answers.hardCover || false,
        bookColor: result,
        userName: quiz_answers.userName,
        textColor: quiz_answers.textColor,
        clientCode: code,
        shippingAddress: {
          userEmail: user?.email || ''.trim(),
          city: userShippingAddress?.city.trim(),
          country_code: userShippingAddress?.country_code.trim(),
          name: userShippingAddress?.name.trim(),
          phoneNumber: userShippingAddress?.phoneNumber.trim(),
          postcode: userShippingAddress?.postcode.trim(),
          state_code: userShippingAddress?.state_code.value.trim(),
          street1: userShippingAddress?.street1.trim(),
          street2: userShippingAddress?.street2.trim(),
        },
      });
      dispatch(
        updateShippingTestState({
          isShippingTest: true,
          addressError: false,
          loading: false,
        }),
      );
      dispatch(
        updateLead(code, {
          quiz_answers: {
            ...quiz_answers,
            shipping_address: {
              userEmail: user?.email || ''.trim(),
              city: userShippingAddress?.city.trim(),
              country_code: userShippingAddress?.country_code.trim(),
              name: userShippingAddress?.name.trim(),
              phoneNumber: userShippingAddress?.phoneNumber.trim(),
              postcode: userShippingAddress?.postcode.trim(),
              state_code: userShippingAddress?.state_code.value.trim(),
              street1: userShippingAddress?.street1.trim(),
              street2: userShippingAddress?.street2.trim(),
            },
          },
        }),
      );
    } catch (e) {
      if (e.response.data.dubplicateOrder) {
        dispatch(
          updateShippingTestState({
            isShippingTest: true,
            addressError: true,
            loading: false,
          }),
        );
        handleError(e);
        throw e;
      }
      if (e.response.data.shipping_address) {
        dispatch(
          updateShippingTestState({
            isShippingTest: true,
            addressError: true,
            loading: false,
          }),
        );
        handleError(e);
        throw e;
      }
    }
  };

  const forwardClient = () => {
    let matchFound = false; // Flag variable to track if a match
    config?.checkout?.pageOrder.forEach(obj => {
      Object.entries(obj).forEach(([key, arr]) => {
        if (matchFound) return; // Exit the loop if a match has already been found

        // Compare the arrays inside it with the second array based on the "key" property
        arr.some(item => {
          const match =
            selected_plans?.find(element => element.key === item) ||
            upsell_products?.find(element => element.key === item);

          if (match) {
            // Call the function with the key from the first array
            switch (key) {
              case 'shipping': {
                goToShipping();
                break;
              }
              case 'register': {
                goToRegister();
                break;
              }
              default:
                goToSuccess();
            }
            matchFound = true; // Set the flag to true to indicate a match has been found
            return true; // Exit the loop when a match is found
          }
        });
      });
    });
  };

  const findMatchingKey = (array, upgradeSequences) => {
    for (const item of Object.keys(upgradeSequences)) {
      if (array.includes(item)) {
        return Object.keys(upgradeSequences[item])[0];
      }
    }

    return null; // Return null if no match is found
  };
  const handleSuccess = async (data: any) => {
    const localisedProductsF = store
      .getState()
      .user.selected_plans?.map(product => getLocalisedProduct(product));

    try {
      const orderId = String(data?.order_id ?? '');
      const eventData = {
        code: code ?? '',
        email: user?.email,
        location: geolocation?.iso_country?.toLocaleLowerCase(),
        orderId,
        data: {
          currencyCode: localisedProductsF[0]?.currency,
          paymentMethod: '',
          transactionId: orderId,
          transactionTotal: parseFloat(calculateFullPrice(localisedProductsF)),
          transactionAffiliation: '',
          transactionProducts: [
            {
              sku: createProductSkyString(localisedProductsF),
              name: createProductNameString(localisedProductsF),
              category: 'Subscription',
              price: parseFloat(calculateFullPrice(localisedProductsF)),
              quantity: 1,
            },
          ],
        },
      };
      Events.paymentSuccessful(eventData);

      const selectedPlanKeys = store
        .getState()
        .user.selected_plans?.map(item => item.key);

      const userBoughtAppSub = store
        .getState()
        .user.selected_plans?.some(item => APP_SUB_KEYS.includes(item.key));

      const upgradeKey = findMatchingKey(
        selectedPlanKeys,
        config?.upgradeSequences,
      );

      if (!userBoughtAppSub && config['upgrade/09']) {
        goToUpgrade('upgrade/09');
      } else if (upgradeKey) {
        goToUpgrade(upgradeKey);
      } else {
        forwardClient();
      }
      if (
        location.search.includes('lc2') &&
        selected_plans_options?.paperBook
      ) {
        //add check for physical book
        dispatch(
          updateShippingTestState({
            isShippingTest: true,
            addressError: false,
            loading: false,
          }),
        );
        const bookString = quiz_answers.bookImageString;
        const index = bookString.indexOf('#');
        const finalString = bookString.substring(index);
        const result = finalString.replace(/\.png$/, '');
        axios.post(endpointConfig.STORE_PENDING_SHIPPING_ADDRESS, {
          clientCode: code ?? '',
          bookColor: result,
          userName: quiz_answers.userName,
          textColor: quiz_answers.textColor,
          timeStamp: new Date().getTime(),
          shippingAddress: {
            userEmail: user?.email || ''.trim(),
            city: userShippingAddress?.city.trim(),
            country_code: userShippingAddress?.country_code.trim(),
            name: userShippingAddress?.name.trim(),
            phoneNumber: userShippingAddress?.phoneNumber.trim(),
            postcode: userShippingAddress?.postcode.trim(),
            state_code: userShippingAddress?.state_code,
            street1: userShippingAddress?.street1.trim(),
            street2: userShippingAddress?.street2.trim(),
          },
        });
      }

      if (
        localisedProductsF?.some(
          obj =>
            obj?.key === 'e-book-free' ||
            obj?.key === 'e-book' ||
            obj?.key === 'e-book-downsell' ||
            obj?.key === 'e-book-downsell-cm',
        )
      ) {
        await generateEbookLink();
      }

      try {
        const funnelData = {
          code: code ?? '',
          quizAnswers: quiz_answers ?? {},
          selectedPlan: store.getState().user.selected_plans ?? {},
          quiz,
        };
        await axios.post(endpointConfig.REACT_APP_FIREBASE_USER_HAS_PAYED_URL, {
          email: user?.email,
          funnel: funnelData,
        });
      } catch (e) {
        handleError(e);
      }
      // createHyrosOrder({ orderId: Number(orderId) });
    } catch (e) {
      handleError(e);
    }
  };

  const handleOnClose = () => {
    window.history.pushState(
      null,
      '',
      `${location.pathname.replaceAll(/\/payments/g, '')}${location.search}`,
    );
    Tracking.logEvent({
      event: 'payment-window-clicks',
      funnel: quiz,
      widget: 'payment-window-turned-off',
      product: createProductNameString(localisedProducts),
    });
    Tracking.logEvent({
      event: 'payment-window-turned-off',
      client_code: code,
    });

    setModalOpen(false);
    if (data?.discountAvailable) {
      return goToDiscount();
    }
  };

  // Fixes screen jumping when entering credit card number
  useEffect(() => {
    const bodyWrapper = document.getElementById('body-wrapper');
    if (!bodyWrapper) {
      return;
    }

    // bodyWrapper.style.height = `${height}px`;

    // return () => {
    //   bodyWrapper.style.height = '';
    // };
  }, [height]);

  return (
    <>
      <Seo />

      <PaymentDialog
        selectedProducts={selected_plans}
        isOpen={true}
        onRequestClose={handleBack}
        onSuccess={handleSuccess}
        orderSummarySubItems={data?.orderSummarySubItems}
      />
    </>
  );
};

export default Payments;
