import React, { FC, useEffect } from 'react';
import { Props, Styles } from 'react-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Modal, Text } from 'components';
import PaymentDialogOrderSummary from './PaymentDialogOrderSummary';
import PaymentsForm from './PaymentsForm';
import EmailInput from './EmailInput';
import { AppState } from 'state/types';
import PaymentSteps from './PaymentSteps';
import SecureFooter from 'assets/icons/payments/secure-footer.svg';
import ExcludeIcon from 'assets/icons/lock-solid.svg';
import DolorIcon from 'assets/icons/Money.svg';
import ExclamationIcon from 'assets/icons/info-circle-solid.svg';
import PaymentIcons from 'assets/icons/payment-image.svg';
import Info from 'assets/icons/payments/info.svg';
import Arrow from 'assets/icons/payments/arrow.svg';

import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { useQuizData } from 'utils/hooks';
import { OrderSummarySubItem } from 'types/checkout';
import { Product } from 'types/product';
import { getLocalisedProduct } from 'utils/localization';
import { useLocation } from '@reach/router';

interface PaymentDialogProps {
  selectedProducts: Product[] | null;
  onSuccess: (data: any) => Promise<void>;
  orderSummarySubItems?: OrderSummarySubItem[];
  onRequestClose: () => void;
}

const ContentContainer = styled.div`
  padding: 0 1rem 3rem 1rem;

  max-width: 46.375rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media ${tablet} {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 1rem 1.5rem 1rem;
    justify-content: unset;
    max-width: unset;
    gap: 1.5rem;
  }
`;

const PaymentStepsContainer = styled.div`
  max-width: 15.75rem;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  padding: 1.5rem 0;
`;

const SecureFooterStyled = styled(SecureFooter)`
  margin-top: 1rem;
  width: 100%;
`;

const EmailInputContainer = styled.div`
  padding-bottom: 0.375rem;
`;

const SecuredPaymentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;

const ExludeIconStyled = styled(ExcludeIcon)`
  width: 100%;
  height: 100%;
  max-height: 18px;
  max-width: 18px;
  margin-right: 0.75rem;
`;

const DolorIconStyled = styled(DolorIcon)`
  margin-right: 0.75rem;
  width: 100%;
  height: 100%;
  max-height: 18px;
  max-width: 18px;
`;

const ExclamationIconStyled = styled(ExclamationIcon)`
  margin-right: 0.75rem;
  width: 100%;
  height: 100%;
  max-height: 18px;
  max-width: 18px;
`;

const StyledPaymentIcons = styled(PaymentIcons)`
  width: 12rem;
  @media ${mobile} {
    width: 11.906rem;
  }
`;
const SecureTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const SecureTextItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const SecureTextItemContainerLast = styled.div<{ isLast?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 1.9rem;
`;

const SecureTextPayment = styled.div<{ isLast?: boolean }>`
  display: flex;
  align-items: center;
  padding-bottom: ${({ isLast }) => (isLast ? '0' : '1.25rem')};
`;

const PaymentDialog: FC<PaymentDialogProps & Props> = ({
  selectedProducts,
  onSuccess,
  orderSummarySubItems,
  onRequestClose,
  ...props
}) => {
  const { user, subscription_config, selected_plans } = useSelector(
    (state: AppState) => state.user,
  );

  const location = useLocation();
  const isCheckoutSpecial = location.pathname.includes('checkout-special');

  const data = useQuizData('payments');

  const { isMobile } = useQuery();
  const allProducts = useSelector(
    (state: AppState) => state.funnel.products,
  ) as Product[];

  const products = selectedProducts?.map(product =>
    getLocalisedProduct(product as Product),
  );

  const hasFreeEbook = products?.some(product => product.key === 'e-book-free');
  const ebookProduct = allProducts.find(product =>
    isCheckoutSpecial
      ? product.key === 'e-book-downsell'
      : product.key === 'e-book',
  );

  // const currency = products[0]?.currencyId;

  const ebookRegularPrice = getLocalisedProduct(ebookProduct)?.regularPrice;

  const selectedPlansOptions = useSelector(
    (state: AppState) => state.user.selected_plans_options,
  );

  const currency = products[0]?.currency;

  const isSubSelected = products[0]?.months > 0;

  const customStyle: Styles = {
    content: {
      padding: 0,
    },
  };

  const mobileStyle: Styles = {
    content: {
      padding: 0,
      width: 'calc(100% - 2rem)',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'calc(100% - 3rem)',
      borderRadius: 16,
    },
  };

  const calculateFinalPrice = () => {
    let fullPrice = 0;

    for (let i = 0; i < products.length; i++) {
      fullPrice = fullPrice + Number(products[i].finalPrice);
    }
    return fullPrice.toFixed(2);
  };

  const calculateDiscount = () => {
    let fullPrice = 0;

    for (let i = 0; i < products.length; i++) {
      fullPrice = fullPrice + Number(products[i].discount);
    }
    if (hasFreeEbook) {
      fullPrice = fullPrice + Number(ebookRegularPrice);
    }

    return fullPrice.toFixed(2);
  };

  const calculateFullPrice = () => {
    let fullPrice = 0;

    for (let i = 0; i < products.length; i++) {
      fullPrice = fullPrice + Number(products[i].regularPrice);
    }
    if (hasFreeEbook) {
      fullPrice = fullPrice + Number(ebookRegularPrice);
    }
    return fullPrice.toFixed(2);
  };

  const RenderPayments = ({ children }) => (
    <>
      {isMobile ? (
        { ...children }
      ) : (
        <Modal
          width="27.25rem"
          style={isMobile ? mobileStyle : customStyle}
          {...props}
        >
          {children}
        </Modal>
      )}
    </>
  );

  return (
    // <Modal
    //   width="27.25rem"
    //   style={isMobile ? mobileStyle : customStyle}
    //   {...props}
    // >
    // <RenderPayments>
    <>
      <ContentContainer>
        <Outer>
          <PaymentsForm onSuccess={onSuccess} />
          {isSubSelected && (
            <SecureTextContainer>
              <SecureTextItemContainer>
                <SvgContainer>
                  <Info />
                </SvgContainer>
                <InfoText>{data?.renewInfo}</InfoText>
              </SecureTextItemContainer>
              <SecureTextItemContainer>
                <SvgContainer>
                  <Arrow />
                </SvgContainer>

                <InfoText>
                  {currency}
                  {products[0]?.finalPrice} {data?.paymentText[products[0].key]}
                </InfoText>
              </SecureTextItemContainer>
            </SecureTextContainer>
          )}
        </Outer>

        <SummaryContainer>
          <SummaryTitle>
            Lasting Change {isSubSelected && 'App'}
            {selectedPlansOptions?.paperBook &&
              `${isSubSelected ? ' + ' : ''}Paperback`}
            {selectedPlansOptions?.ebookCheck &&
              `${
                isSubSelected || selectedPlansOptions?.paperBook ? ' + ' : ''
              }Ebook`}
          </SummaryTitle>

          <SummaryInner>
            <SummaryText>
              {products?.length} {products?.length > 1 ? 'products' : 'product'}
            </SummaryText>
            <ProductsPrice>
              {currency}
              {calculateFullPrice()}
            </ProductsPrice>
          </SummaryInner>
          <SummaryInner>
            <SummaryText>{data?.discount}</SummaryText>
            <ProductDiscount>
              -{currency}
              {calculateDiscount()}
            </ProductDiscount>
          </SummaryInner>
          <Divider />
          <SummaryInner>
            <Total>{data?.total}</Total>
            <Total>
              {currency}
              {calculateFinalPrice()}
            </Total>
          </SummaryInner>
        </SummaryContainer>

        {/* {isMobile ? (
        isSubSelected ? (
          <ShippingImage alt="shippingImage" src={data?.shippingImage_mob} />
        ) : (
          <ShippingImage alt="shippingImage" src={data?.no_sub_shipping} />
        )
      ) : null} */}
      </ContentContainer>
      <BackContainer onClick={onRequestClose}>{data?.back}</BackContainer>
    </>

    // </RenderPayments>
  );
};

export default PaymentDialog;

const Outer = styled.section`
  display: flex;
  flex-direction: column;
`;

const SvgContainer = styled.section`
  width: 1.5rem;
  height: 1.5rem;
`;

const InfoText = styled.p`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.014rem;
`;

const ShippingImage = styled(DynamicImage)`
  width: 568px;
  height: 86.54px;
  margin-top: 24px;
  margin-bottom: 64px;
  @media ${mobile} {
    width: 100%;
    height: 130px;
    margin: 16px 0 8px;
  }
`;

const SummaryContainer = styled.section`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
  border-radius: 0.625rem;
  border: 1px solid rgba(119, 119, 119, 0.15);
  background: #fff;
  box-shadow: 0px 4.935px 16px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 21.1875rem;
  height: fit-content;
  @media ${tablet} {
    max-width: unset;
  }
`;

const SummaryTitle = styled.h2`
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Open Sans';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: -0.018rem;
`;

const SummaryInner = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SummaryText = styled.p`
  color: #000;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.014rem;
`;

const ProductsPrice = styled.p`
  color: #000;
  text-align: right;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.014rem;
`;

const ProductDiscount = styled(ProductsPrice)`
  color: #2fbe8f;
`;

const Total = styled.p`
  color: #000;
  font-family: 'Open Sans';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.016rem;
`;

const Divider = styled.section`
  background: rgba(119, 119, 119, 0.2);
  width: 100%;
  height: 0.0625rem;
`;

const BackContainer = styled.p`
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  color: #804da1;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.024rem;
  text-transform: uppercase;
  padding-bottom: 2rem;
`;
